import * as React from 'react';
import { Box, Button, CircularProgress, Drawer } from '@mui/material';
import {
  FirebaseAuthentication,
  SignInResult,
} from '@capacitor-firebase/authentication';
import {
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithCredential,
  RecaptchaVerifier,
  signInWithPhoneNumber as firebaseSignInWithPhoneNumber,
} from 'firebase/auth';
import AppleIcon from '@mui/icons-material/Apple';
import GoogleIcon from '@mui/icons-material/Google';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import IosMobileSignInWithEmailAndPassword from 'app/pages/SigninPageMobile/iosMobileSignIn';
import { Device, DeviceInfo } from '@capacitor/device';
import { FirebaseError } from '@firebase/app';
import { Capacitor } from '@capacitor/core';

interface SigninPageUIProps {
  loginCallBackend: (authResult: any) => Promise<void>;
}

const SigninPageUI: React.FC<SigninPageUIProps> = ({ loginCallBackend }) => {
  const [loggingApple, setloggingApple] = React.useState(false);
  const [loggingGoogle, setLoggingGoogle] = React.useState(false);
  const [loggingEmail, setLoggingEmail] = React.useState(false);
  const [loggingPhoneNumber, setLoggingPhoneNumber] = React.useState(false);
  const [inputPhoneNumber, setInputPhoneNumber] = React.useState('');
  const [deviceResult, setDeviceResult] = React.useState<DeviceInfo | ''>();
  const [recaptchaVerifier, setRecaptchaVerifier] =
    React.useState<RecaptchaVerifier | null>(null);

  /**
   * If we use third party login, according to Apple, you need to add their login too. Otherwise, apple will not approve your app.
   * @returns
   */
  const loginWithApple = async () => {
    try {
      setloggingApple(true);
      // 1. Create credentials on the native layer
      const result = await FirebaseAuthentication.signInWithApple();
      await loginCallBackend(result);
      // 2. Sign in on the web layer using the id token and nonce
      const provider = new OAuthProvider('apple.com');
      const credential = provider.credential({
        idToken: result.credential?.idToken,
        rawNonce: result.credential?.nonce,
      });
      const auth = getAuth();
      setloggingApple(false);
      return await signInWithCredential(auth, credential);
    } catch (error) {
      setloggingApple(false);
    }
  };

  /**
   * For ios use. For some reason, SignInScreenWithFirebaseUI google login doesn't jump back to the ios app
   */
  const signInWithGoogle = async () => {
    try {
      setLoggingGoogle(true);
      const result = await FirebaseAuthentication.signInWithGoogle();
      await loginCallBackend(result);
      const credential = GoogleAuthProvider.credential(
        result.credential?.idToken,
      );
      const auth = getAuth();
      await signInWithCredential(auth, credential);
      setLoggingGoogle(false);
      return result.user;
    } catch (error) {
      setLoggingGoogle(false);
    }
  };

  /**
   * I will leave those function here for now. At the beginning SignInScreenWithFirebaseUI is not working in ios device due the schema can not be changed to http or https. But after installed the firebase authenticator pod libs, it is working now. I don't know why
   */
  const signInWithPhoneNumber = async () => {
    /**
     * phone number format need to be like this: +16470000000
     */
    const isNative = Capacitor.isNativePlatform();

    if (isNative) {
      FirebaseAuthentication.signInWithPhoneNumber({
        phoneNumber: inputPhoneNumber,
      });
      FirebaseAuthentication.addListener(
        'phoneVerificationFailed',
        async function ({ message }) {
          window.confirm(message);
          await FirebaseAuthentication.removeAllListeners();
        },
      );
      FirebaseAuthentication.addListener(
        'phoneCodeSent',
        async function ({ verificationId }) {
          const verificationCode = window.prompt(
            'Please enter the verification code that was sent to your mobile device.',
          );

          if (verificationCode) {
            try {
              const result = await FirebaseAuthentication.signInWithPhoneNumber(
                {
                  verificationId,
                  verificationCode,
                },
              );
              try {
                if (!result.additionalUserInfo) {
                  result.additionalUserInfo = { isNewUser: true };
                }
                result.additionalUserInfo.providerId = 'phone';
              } catch (error) {}

              await loginCallBackend(result);
            } catch (error) {
              /**
               * This is when user enter a wrong verification code.
               * For some reason, the above event listener phoneVerificationFailed is not triggered
               */
              window.confirm('Phone login failed');
            }
          } else {
            /**
             * Here is when user click the cancel button of the window promt
             */
          }
          /**
           * Remove event listener or it will listen multiple times
           */
          await FirebaseAuthentication.removeAllListeners();
        },
      );
    } else {
      // Web login with phone number
    }
  };

  const createUserWithEmailAndPassword = async (
    email: string,
    password: string,
  ) => {
    let loginResult: SignInResult;
    try {
      const createResult =
        await FirebaseAuthentication.createUserWithEmailAndPassword({
          email,
          password,
        });
      /**
       * if create account successfully, we login automatically
       */
      loginResult = await FirebaseAuthentication.signInWithEmailAndPassword({
        email,
        password,
      });
      /**
       * require by backend
       */
      loginResult.additionalUserInfo = {
        providerId: 'password',
        isNewUser: true,
      };
      await loginCallBackend(loginResult);
      return createResult.user;
    } catch (error: any) {
      console.log('265', error);
      if (error instanceof FirebaseError) {
        if (error.code === 'auth/email-already-in-use') {
          try {
            /**
             * 1. error could be user already create an account with that email. So we will try to sign in the user
             */
            loginResult =
              await FirebaseAuthentication.signInWithEmailAndPassword({
                email,
                password,
              });

            /**
             * require by backend
             */
            loginResult.additionalUserInfo = {
              providerId: 'password',
              isNewUser: false,
            };
            await loginCallBackend(loginResult);
          } catch (error: any) {
            console.log('291', error.message);
            if (error.message) {
              window.confirm(error.message);
            }
          }
        }
      }
    }
  };
  const openSignInWithEmailModal = (open: boolean) => {
    setLoggingEmail(open);
  };
  const openSignInWithPhoneNumber = (open: boolean) => {
    setLoggingPhoneNumber(open);
  };

  React.useEffect(() => {
    Device.getInfo().then(result => {
      setDeviceResult(result);
    });
  }, []);

  return (
    <>
      <Box
        component="div"
        sx={{
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '10px',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {deviceResult?.toString() === 'ios' && (
          <Button
            style={{ marginBottom: 15, width: 220, textTransform: 'none' }}
            startIcon={
              loggingApple ? (
                <CircularProgress size={20} style={{ color: '#f58220' }} />
              ) : (
                <AppleIcon />
              )
            }
            variant="outlined"
            onClick={loginWithApple}
          >
            Login with Apple
          </Button>
        )}
        <Button
          data-testid="Google login"
          style={{ width: 220, marginBottom: 15, textTransform: 'none' }}
          startIcon={
            loggingGoogle ? (
              <CircularProgress size={20} style={{ color: '#f58220' }} />
            ) : (
              <GoogleIcon />
            )
          }
          variant="outlined"
          onClick={signInWithGoogle}
        >
          Login with Google
        </Button>
        <Button
          data-testid="Email login"
          style={{ width: 220, marginBottom: 15, textTransform: 'none' }}
          startIcon={
            loggingEmail ? (
              <CircularProgress size={20} style={{ color: '#f58220' }} />
            ) : (
              <EmailIcon />
            )
          }
          variant="outlined"
          onClick={() => openSignInWithEmailModal(true)}
        >
          Login with Email
        </Button>
        {Capacitor.isNativePlatform() && (
          <Button
            data-testid="Phone login"
            style={{ width: 220, marginBottom: 15, textTransform: 'none' }}
            startIcon={
              loggingPhoneNumber ? (
                <CircularProgress size={20} style={{ color: '#f58220' }} />
              ) : (
                <PhoneIcon />
              )
            }
            variant="outlined"
            onClick={() => openSignInWithPhoneNumber(true)}
          >
            Login with Phone
          </Button>
        )}
      </Box>
      <Drawer
        anchor={'bottom'}
        open={loggingEmail}
        onClose={() => openSignInWithEmailModal(false)}
      >
        <IosMobileSignInWithEmailAndPassword
          createUserWithEmailAndPassword={createUserWithEmailAndPassword}
        />
      </Drawer>
      <Drawer
        style={{ height: 100 }}
        anchor={'bottom'}
        open={loggingPhoneNumber}
        onClose={() => openSignInWithPhoneNumber(false)}
      >
        <Box
          margin={'0 60px'}
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="20vh"
        >
          <PhoneInput
            inputStyle={{ width: 250 }}
            country={'ca'}
            onChange={(value, country, e, formattedValue) =>
              setInputPhoneNumber(formattedValue)
            }
          />
          <Button
            style={{ width: 300, marginLeft: 20, textTransform: 'none' }}
            variant="outlined"
            onClick={signInWithPhoneNumber}
          >
            Verify
          </Button>
        </Box>
        <div id="recaptcha-container"></div>
      </Drawer>
    </>
  );
};

export default SigninPageUI;
